<template>
  <div>
    <Header back="返回" title="部门管理" index="首页" titleOne="组织管理" titleTwo="部门管理" beforeTitle="新增/编辑" />
    <div class="content">
      <h1>部门信息</h1>
      <el-form label-position="top" ref="form" :model="fromParams" label-width="80px">
        <el-form-item label="部门名称">
          <el-input v-model="fromParams.name" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item label="上级部门（一般指本公司）">
          <el-select style="width:100%" v-model="fromParams.p_id" placeholder="请选择上级部门">
            <el-option
              v-for="(item,index) in organSelect"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item style="width:45%" label="部门主管（可以与角色管理主管名称是同一个人，无直接关系）">
          <el-input v-model="fromParams.leader_id"></el-input>
        </el-form-item>-->
        <el-form-item style="width:100%" label="部门描述">
          <el-input type="textarea" :rows="5" v-model="fromParams.desc" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      fromParams: {},
      organSelect: [],//上级部门下拉框
    }
  },
  created () {
    this.getOrganSelect()
    if (this.$route.query.id) {
      this.infoView()
    }
  },
  methods: {
    infoView () {
      this.axios.get('/api/organization/view', { params: { id: this.$route.query.id } }).then((res) => {
        this.fromParams = res.data
      })
    },
    onSubmit () {
      this.btnLoading = true
      this.axios.post('/api/organization/store', this.fromParams).then(() => {
        this.$message.success('保存成功')
        this.$router.go(-1)
        this.btnLoading = false
      })
    },
    getOrganSelect () {
      this.axios.get('/api/organization/select_organization').then((res) => {
        this.organSelect = res.data
      })
    },
  },
}
</script>

<style scoped>
.el-form-item {
  width: 45%;
  display: inline-block;
}
.el-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>